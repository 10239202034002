import React, { useEffect } from "react";

export default function ContactPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container my-5">
      <h1>Contact Us</h1>
      <div className="mt-5">
        <div className="row bg-brand-light rounded py-5">
          <div className="col-md-6 mb-3">
            <h4>Our Contact Email : </h4>
            <span className="fw-bold"><i className="bi bi-envelope"></i> Thegirlsbyavnigupta@gmail.com</span>
          </div>
          <div className="col-md-6 mb-3">
            <h4>Our Contact Phone : </h4>
            <span className="fw-bold"><i className="bi bi-telephone"></i> +91 92594 67373</span>
          </div>
        </div>
      </div>
    </div>
  );
}
