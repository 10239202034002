import axios, { AxiosInstance } from "axios";
import { ResponseCollector } from "./lib/response";
import { IRequest } from "./lib/request";
import { InterceptorCallback } from "./lib/interceptor";
import { getToken } from "firebase/app-check";
import { appCheck } from "../../firebase/firebase.config";
import Cookies from "js-cookie";


export class HttpClient {
    public static instance = new HttpClient();

    private http: AxiosInstance;

    private constructor() {
        this.http = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });
        this.http.interceptors.request.use(async (config) => {
            const result = await getToken(appCheck);
            config.headers['X-APPCHECK']  = result.token;

            const csrfToken = Cookies.get('csrftoken');
            if (csrfToken) {
                config.headers['X-CSRFToken'] = csrfToken;
            }

            return config;
        }, (e) => {
            console.log(e);
        });
    }

    public addRequestInterceptor(on: InterceptorCallback, error: InterceptorCallback) {
        this.http.interceptors.request.use((config) => on(config), (e) => error(e));
    }

    public addResponseInterceptor(on: InterceptorCallback, error: InterceptorCallback) {
        this.http.interceptors.response.use(response => on(response), e => error(e));
    }

    async get({ url, headers = {} }: IRequest): Promise<ResponseCollector> {
        try {
            const response = await this.http.get(url, { headers: headers, withCredentials: true });
            return new ResponseCollector(response.data);
        } catch(e: any) {
            return new ResponseCollector(e.response.data);
        }
    }

    async post({ url, data = {}, headers = {} }: IRequest): Promise<ResponseCollector> {
        try {
            const response = await this.http.post(url, data, { headers: headers, withCredentials: true });
            return new ResponseCollector(response.data);
        } catch(e: any) {
            return new ResponseCollector(e.response.data);
        }
    }

    async put({ url, data = {}, headers = {} }: IRequest): Promise<ResponseCollector> {
        try {
            const response = await this.http.put(url, data, { headers: headers, withCredentials: true });
            return new ResponseCollector(response.data);
        } catch(e: any) {
            return new ResponseCollector(e.response.data);
        }
    }

    async delete({ url, headers = {} }: IRequest): Promise<ResponseCollector> {
        try {
            const response = await this.http.delete(url, { headers: headers, withCredentials: true });
            return new ResponseCollector(response.data);
        } catch(e: any) {
            return new ResponseCollector(e.response.data);
        }
    }
}