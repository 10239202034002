import "../styles/Navbar.css";
import logo from "../../logo.svg";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { toTitleCase } from "../../infra/utils/utility";
import { Search } from "../models/search";
import { useAppSelector } from "../../redux/hooks";
import { Link } from "react-router-dom";
import TrackOrderModal from "./TrackOrderModal";

export default function Navbar({ categories, setSearchDetails }: { categories: { product: any[]; season: any[] } | null; setSearchDetails: React.Dispatch<React.SetStateAction<Search>> }) {
  const cartItemCount = useAppSelector((state) => state.cart.items.length);
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState("");

  const searchProduct = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (query.length > 0) {
      setSearchDetails({ query: query, productCategoryId: null, seasonCategoryId: null });
      if (location.pathname !== "/search") {
        setTimeout(() => {
          navigate("/search");
        }, 200);
      }
    }
  };

  const searchForQuery = (query: string) => {
    setSearchDetails({ query: query, productCategoryId: null, seasonCategoryId: null });
  };

  const searchForProductCategory = (productCategory: number) => {
    setSearchDetails({ query: "", productCategoryId: productCategory.toString(), seasonCategoryId: null });
  };

  const searchForSeasonCategory = (seasonCategory: number) => {
    setSearchDetails({ query: "", productCategoryId: null, seasonCategoryId: seasonCategory.toString() });
  };

  return (
    <nav className="navbar navbar-expand-lg sticky-top bg-brand-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Bootstrap" width="30" height="30" /> <span className="ff-brand">The Girls</span>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/#sale">
                <span className="text-danger fw-bold">Sale</span>
              </a>
            </li>
            {location.pathname !== "/search" && (
              <>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle fw-bold" to="/search" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Shop Now
                  </Link>
                  <ul className="dropdown-menu">
                    {categories?.season.map((category, index) => {
                      return (
                        <li key={`nsc-${category.id}-${index}`}>
                          <Link className="dropdown-item" to="/search" onClick={() => searchForSeasonCategory(category.id)}>
                            {toTitleCase(category.name)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle fw-bold" to="/search" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Collections
                  </Link>
                  <ul className="dropdown-menu">
                    {categories?.product.map((category, index) => {
                      return (
                        <li key={`npc-${category.id}-${index}`}>
                          <Link className="dropdown-item" to="/search" onClick={() => searchForProductCategory(category.id)}>
                            {toTitleCase(category.name)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle fw-bold" to="/search" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Occassions
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/search" onClick={() => searchForQuery("casual")}>
                        Casual
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/search" onClick={() => searchForQuery("formal")}>
                        Formal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/search" onClick={() => searchForQuery("semi formal")}>
                        Semi Formal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/search" onClick={() => searchForQuery("festival looks")}>
                        Festival Looks
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            <li className="nav-item">
              <span className="nav-link" data-bs-toggle="modal" data-bs-target="#trackOrder">
                Track Order
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link" data-bs-toggle="modal" data-bs-target="#replaceOrder">
                Replace
              </span>
            </li>
          </ul>
          <div className="d-flex gap-2">
            {location.pathname !== "/search" && location.pathname !== "/checkout" && (
              <>
                <input className="form-control" type="search" placeholder="Search for cloths..." aria-label="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
                <button className="btn btn-light border" type="submit" onClick={searchProduct}>
                  <i className="bi bi-search"></i>
                </button>
              </>
            )}
            {location.pathname !== "/checkout" && (
              <button className="btn btn-light border position-relative" type="submit" data-bs-toggle="offcanvas" data-bs-target="#cartOffCanvas" aria-controls="offcanvasRight">
                {cartItemCount > 0 ? (
                  <>
                    <i className="bi bi-bag-check-fill"></i>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {cartItemCount}
                      <span className="visually-hidden">Cart</span>
                    </span>
                  </>
                ) : (
                  <i className="bi bi-bag"></i>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
