export class ResponseCollector {
    response: any;

    constructor(response: any) {
        this.response = response;
    }

    get success(): boolean {
        return this.response['success'];
    }

    get data(): any {
        return this.response['data'];
    }

    get error(): string {
        const errors = this.response['errors'];
        return errors[Object.keys(errors)[0]][0];
    }

    static localErrorResponse(error: string = "Something went wrong."): ResponseCollector {
        return new ResponseCollector({
            success: false,
            errors: {
                client: [error]
            }
        });
    }
}