import React from "react";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex align-items-center gap-3">
              <i className="bi bi-truck fs-1"></i>
              <span className="fw-bold">Free Delivery</span>
            </div>
            <span>All over India</span>
          </div>
          <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex align-items-center gap-3">
              <i className="bi bi-arrow-repeat fs-1"></i>
              <span className="fw-bold">4 Days Replacement Policy</span>
            </div>
            <span>Simply replace it within 4 days.</span>
          </div>
          <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex align-items-center gap-3">
              <i className="bi bi-person-check fs-1"></i>
              <span className="fw-bold">Support Monday - Friday</span>
            </div>
            <span>10:00 am to 5:00pm</span>
          </div>
          <div className="col-md-3 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex align-items-center gap-3">
              <i className="bi bi-layers fs-1"></i>
              <span className="fw-bold">Quality Fabric</span>
            </div>
            <span>Made with love and 100% pure fabrics</span>
          </div>
        </div>
      </div>
      <div className="bg-brand-light">
        <div className="container-fluid">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex flex-column align-items-center gap-3">
                  <img className="rounded w-50" src={logo} alt="thegirls" />
                  <span className="fst-italic ff-brand">Wear Quality Be Confident</span>
                  <span className="fw-bold">Thegirlsbyavnigupta@gmail.com</span>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-column align-items-center gap-3 mt-5">
                  <span className="fw-bold">Explore</span>
                  <Link className="link-offset-2 link-underline link-underline-opacity-0 text-dark" to="/">
                    Home
                  </Link>
                  <Link className="link-offset-2 link-underline link-underline-opacity-0 text-dark" to="/about">
                    About
                  </Link>
                  <Link className="link-offset-2 link-underline link-underline-opacity-0 text-dark" to="/contact">
                    Contact
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-column align-items-center gap-3 mt-5">
                  <span className="fw-bold">Quick Links</span>
                  <span className="link-offset-2 link-underline link-underline-opacity-0 text-dark" data-bs-toggle="modal" data-bs-target="#trackOrder">
                    Track Order
                  </span>
                  <span className="link-offset-2 link-underline link-underline-opacity-0 text-dark" data-bs-toggle="modal" data-bs-target="#replaceOrder">
                    Replace
                  </span>
                  <Link className="link-offset-2 link-underline link-underline-opacity-0 text-dark" to="/privacy">
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-3 d-flex flex-column">
        <span>Copyright &copy; 2024 The Girls | All Right Reserved</span>
        <span>
          Design & Develop by{" "}
          <a className="link-offset-2 link-underline link-underline-opacity-0" href="https://conceptune.com">
            Conceptune
          </a>
        </span>
      </div>
    </div>
  );
}
