import React, { useEffect, useState } from "react";
import { ProductService } from "../services/product-service";
import ProductItem from "./ProductItem";
import ProductViewModal from "./ProductViewModal";
import { Product } from "../models/product";

export default function SaleProduct() {
  const [saleProducts, setSaleProducts] = useState<Product[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const onSelectProduct = (event: React.MouseEvent<HTMLButtonElement>, product: Product) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedProduct(product);
  };

  useEffect(() => {
    const fetchSale = async () => {
      const response = await ProductService.instance.fetchSale();
      if (response.success) {
        setSaleProducts(response.data.products);
      }
    };

    fetchSale();
  }, []);

  return (
    <div className="container mt-5" id="sale">
      {saleProducts && saleProducts.length > 0 && (
        <>
          <div className="text-center">
            <span className="fw-bold fs-4 ff-brand">Sale is Live</span>
          </div>
          <div className="row row-cols-auto gap-3 mt-5">
            {saleProducts.map((product, index) => (
              <div className="col">
                <ProductItem key={`saleproduct-${product.id}-${index}`} modalId="SaleProduct" product={product} onSelect={onSelectProduct} />
              </div>
            ))}
          </div>
          <ProductViewModal modalId="SaleProduct" product={selectedProduct} />
        </>
      )}
    </div>
  );
}
