import { HttpClient } from "../../infra/http/http-client";
import { Search } from "../models/search";

export class ProductService {
    public static instance = new ProductService();
    private http: HttpClient;

    private constructor() {
        this.http = HttpClient.instance;
    }

    fetchProduct(id: number) {
        return this.http.get({url: `products/product/?id=${id}`});
    }

    search({query = '', productCategoryId, seasonCategoryId} : Search) {
        let url = `products/search/?q=${query}`;

        if (productCategoryId) {
            url += `&pc=${productCategoryId}`;
        }

        if (seasonCategoryId) {
            url += `&sc=${seasonCategoryId}`;
        }

        return this.http.get({ url });
    }

    fetchSale() {
        return this.http.get({url: 'products/sale/'});
    }
}