import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CartItem } from '../models/cart';


export interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: [],
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      state.items.push(action.payload);
    },
    removeFromCart: (state, action: PayloadAction<{ itemId: string}>) => {
      state.items = state.items.filter(item => item.id !== action.payload.itemId);
    },
    clearCart: (state) => {
      state.items = [];
    },
    updateItemQuantity: (state, action: PayloadAction<{ itemId: string, quantity: number }>) => {
      const index = state.items.findIndex(item => item.id === action.payload.itemId);
      if (index >= 0) {
        state.items[index].quantity = action.payload.quantity;
      }
    }
  },
})

export const { addToCart, removeFromCart, clearCart, updateItemQuantity } = cartSlice.actions
export default cartSlice.reducer