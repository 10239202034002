import React, { useCallback, useEffect, useState } from "react";
import { ProductService } from "../services/product-service";
import { useParams } from "react-router";
import NetworkImage from "../components/NetworkImage";
import SuggestedProduct from "../components/SuggestedProduct";
import Spinner from "../components/Spinner";
import { toTitleCase } from "../../infra/utils/utility";
import { Product } from "../models/product";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addToCart } from "../features/cartSlice";
import { id } from "../../infra/utils/utility";
import "../styles/ProductViewModal.css";

export default function ProductPage() {
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart);
  const { productId } = useParams();
  const [product, setProduct] = useState<Product | null>(null);
  const [productPhoto, setProductPhoto] = useState<string>("");
  const [selectedSize, setSelectedSize] = useState<string | null>(product ? product.sizes.split(",")[0] : "S");
  const [selectedQuantity, setQuantity] = useState(1);

  const fetchProduct = useCallback(() => {
    ProductService.instance.fetchProduct(parseInt(productId!)).then((response) => {
      if (response.success) {
        setProduct(response.data.product);
        setProductPhoto(response.data.product.photo_1);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  }, [productId]);

  const addProductToCart = () => {
    dispatch(addToCart({ id: id(), product: product!, size: selectedSize!, quantity: selectedQuantity }));
  };

  const updateQuantity = (type: "inc" | "dec") => {
    if (type === "inc" && selectedQuantity < product!.stock_count) {
      setQuantity(selectedQuantity + 1);
    } else if (type === "dec" && selectedQuantity > 1) {
      setQuantity(selectedQuantity - 1);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  return (
    <div>
      {product ? (
        <div className="bg-brand-light">
          <div className="container pt-3">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-2">
                    <div className="d-flex pvm-pp-img-select gap-2">
                      <div className={`border ${productPhoto === product.photo_1 ? "border-black" : ""}`} onClick={() => setProductPhoto(product.photo_1)}>
                        <NetworkImage className="img-fluid" src={product.photo_1} alt={product.name} />
                      </div>
                      {product.photo_2 && (
                        <div className={`border ${productPhoto === product.photo_2 ? "border-black" : ""}`} onClick={() => setProductPhoto(product.photo_2!)}>
                          <NetworkImage className="img-fluid" src={product.photo_2} alt={product.name} />
                        </div>
                      )}
                      {product.photo_3 && (
                        <div className={`border ${productPhoto === product.photo_3 ? "border-black" : ""}`} onClick={() => setProductPhoto(product.photo_3!)}>
                          <NetworkImage className="img-fluid" src={product.photo_3} alt={product.name} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-10">
                    <NetworkImage className="img-fluid" src={productPhoto} alt={product.name} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="mt-3 mb-1 ff-brand">{toTitleCase(product.name)}</h3>
                <div className="mb-3 d-flex align-items-center gap-3">
                  <span className="fs-5 text-danger">Rs. {product.price}</span>
                  {product.discount_percent > 0 && <span className="fs-5 text-decoration-line-through">Rs. {product.total_price}</span>}
                  {product.is_on_sale && <span className="badge bg-danger">SALE</span>}
                </div>
                <div className="mb-3">
                  <p className="mb-1">{product.description}</p>
                </div>
                <div className="mb-3">
                  <i className="bi bi-box-seam"></i> <span>Only {product.stock_count} Left</span>
                </div>
                <div className="mb-3">
                  <span>Select size</span>
                  <div className="d-flex flex-wrap gap-2">
                    {product.sizes.split(",").map((size: string, index: number) => {
                      const key = size + index;
                      return (
                        <div key={key} className={`d-flex justify-content-center align-items-center border rounded pvm-size cursor-pointer ${selectedSize === size ? "badge bg-dark border-black" : ""} `} onClick={() => setSelectedSize(size)}>
                          <span className="px-1">{size}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mb-3">
                  <span>Quantity</span>
                  <div className="input-group mb-3 w-50">
                    <button className="btn btn-light" type="button" onClick={() => updateQuantity("dec")}>
                      <i className="bi bi-dash-lg"></i>
                    </button>
                    <input type="number" className="form-control" value={selectedQuantity} min={1} max={product.stock_count} onChange={() => {}} />
                    <button className="btn btn-light" type="button" onClick={() => updateQuantity("inc")}>
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 mb-3">
                  {cart.items.find((item) => item.product.id === product.id) && (
                    <button type="button" className="btn btn-success" data-bs-toggle="offcanvas" data-bs-target="#cartOffCanvas" aria-controls="offcanvasRight">
                      <i className="bi bi-bag-check"></i> View in Cart
                    </button>
                  )}
                  <button type="button" className="btn btn-primary" onClick={addProductToCart}>
                    <i className="bi bi-bag-check"></i> Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner marginTop="100px" />
      )}
      {product && <SuggestedProduct key={"suggestedproducts"} query={product.name} productCategoryId={product.product_category ? product.product_category.toString() : null} seasonCategoryId={product.season_category ? product.season_category.toString() : null} />}
    </div>
  );
}
