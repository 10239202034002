import React, { useEffect } from "react";

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="container my-5">
      <h1>About Us</h1>
      <div className="mt-5">
        <p>
          Welcome to <strong>The Girls by Avni Gupta</strong>, a fashion and style brand that celebrates the essence of Indian women through timeless, elegant clothing. Founded with a passion for empowering women, we create thoughtfully designed garments that blend tradition with contemporary flair.
        </p>
        <p>Our brand is built on the belief that fashion should be authentic, comfortable, and empowering. Every piece in our collection is crafted with love, using only 100% pure fabrics, ensuring that our clothes are as luxurious as they are sustainable. We take pride in sourcing the finest materials and working with skilled artisans who bring each design to life, honoring India's rich textile heritage.</p>
        <p>At The Girls, we understand that clothing is more than just fabric—it's an expression of who you are. Our designs are created for women who value individuality, confidence, and grace. Whether you're looking for an outfit that speaks to your cultural roots or a piece that complements your modern lifestyle, our collection offers a versatile range of styles for every occasion.</p>
        <p>Thank you for being part of our journey. With The Girls by Avni Gupta, you’re not just wearing fashion—you’re wearing a story of craftsmanship, love, and the celebration of the Indian woman.</p>
        <p>
          <span className="fw-bold fs-3">"</span>
          <strong>Embrace the elegance of true craftsmanship—Wear Quality, Be Confident with The Girls by Avni Gupta.</strong>
          <span className="fw-bold fs-3">"</span>
        </p>
      </div>
    </div>
  );
}
