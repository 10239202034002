import "../styles/Banner.css";
import React, { useEffect, useState } from "react";
import { CommonService } from "../services/common-service";
import NetworkImage from "../components/NetworkImage";
import Spinner from "./Spinner";

export default function Banner() {
  const [photos, setPhotos] = useState<string[] | null>(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      const response = await CommonService.instance.fetchFrontPhotos();
      if (response.success) {
        setPhotos(response.data.photos.map((photo: any) => photo.image));
      }
    }

    fetchPhotos();
  }, []);

  return (
    <div className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {
            photos?.map((photo, index) => (
              <div key={`photo-${index}`} className={`carousel-item ${index === 0? "active" : ""}`}>
                <NetworkImage className="d-block w-100 cursor-pointer banner-img" src={photo} alt="..."/>
              </div>
            )) || <Spinner marginTop="100px"/>
        }
      </div>
    </div>
  );
}
