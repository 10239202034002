import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { OrderService } from "../services/order-service";
import Alert from "../components/Alert";
import NetworkImage from "../components/NetworkImage";
import { toTitleCase } from "../../infra/utils/utility";
import { OrderStatus, PaymentStatus } from "../../constants/constants";
import { Link } from "react-router-dom";

export default function ReplacePage() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState<any>(null);
  const [replacementItemIds, setReplacementItemIds] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [formType, setFormType] = useState<"replacement" | "verification" | "confirmation" | "failure">("replacement");
  const [verifyForm, setVerifyForm] = useState({ otp: "" });

  const handleItemChecked = (event: React.FormEvent<HTMLInputElement>) => {
    const ids = replacementItemIds.split(",");
    if (ids.includes(event.currentTarget.value)) {
      setReplacementItemIds(ids.filter((id) => id !== event.currentTarget.value).join(","));
    } else {
      setReplacementItemIds(replacementItemIds === "" ? event.currentTarget.value : ids.concat(event.currentTarget.value).join(","));
    }
  };

  const onVerifyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyForm({ otp: event.target.value });
  };

  const validateReplacementForm = () => {
    if (replacementItemIds.length === 0) return "No Item Selected to replace.";
    return null;
  };

  const replaceOrder = () => {
    const error = validateReplacementForm();
    if (error) {
      setError(error);
    } else {
      setError(null);

      OrderService.instance.replaceOrder(orderId!, { product_items: replacementItemIds }).then((response) => {
        if (response.success) {
          setFormType("verification");
        } else {
          setError(response.error);
        }
      });
    }
  };

  const validateVerifyForm = () => {
    if (verifyForm.otp.length !== 6) return "Invalid otp, must be 6 digits.";
    return null;
  };

  const verifyReplacement = () => {
    const error = validateVerifyForm();
    if (error) {
      setError(error);
    } else {
      setError(null);

      OrderService.instance.verifyReplacement({ otp: verifyForm.otp }).then((response) => {
        if (response.success) {
          setFormType("confirmation");
        } else {
          setError("Invalid OTP or expired. Try again.");
        }
      });
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      if (orderId) {
        const response = await OrderService.instance.fetchOrder(orderId);
        if (response.success) {
          if (response.data.order.order_status !== OrderStatus.DELIVERED) {
            navigate("/");
            return;
          }
          setOrder(response.data.order);
        }
      }
    };

    fetchOrder();
  }, [orderId]);

  return (
    <div className="container mt-5">
      <span className="h3 ff-brand">Order Replace - {order?.id.substring(6)}</span>
      <p>By shopping with The Girls by Avni Gupta, you agree to our policies and understand the scope of our refund and replacement terms.</p>
      {error && (
        <div className="mt-3">
          <Alert theme="danger" message={error} title="Oops!" />
        </div>
      )}
      <div className="row my-5">
        <div className="col-6">
          {formType === "replacement" ? (
            <>
              {order?.is_canceled && <Alert theme="danger" title="Oops!" message="This order was canceled. Your amount will be refunded or may already be refunded. Contact customer support if not. Happy Shopping!" />}
              <div className="mb-3">
                <p>
                  <strong>Customer Name: </strong> {order?.customer.full_name}
                </p>
                <p>
                  <strong>Delivery Address: </strong> {order?.delivery_address.address}
                </p>
                <p>
                  <strong>City: </strong> {order?.delivery_address.city}
                </p>
                <p>
                  <strong>State: </strong> {order?.delivery_address.state}
                </p>
                <p>
                  <strong>Pincode: </strong> {order?.delivery_address.pincode}
                </p>
              </div>
            </>
          ) : formType === "verification" ? (
            <>
              <div className="mb-3">
                <label htmlFor="otp" className="form-label">
                  Enter the OTP sent on your email - {order?.customer.email}
                </label>
                <input type="number" className="form-control" id="otp" placeholder="xxxxxx" min={6} max={6} name="otp" onChange={onVerifyInputChange} value={verifyForm.otp} />
              </div>
              <div className="mb-3 d-flex justify-content-between">
                <div>
                  {error && (
                    <button className="btn btn-outline-dark" onClick={replaceOrder}>
                      <i className="bi bi-arrow-clockwise"></i> Resent OTP
                    </button>
                  )}
                </div>
                <div>
                  <button className="btn btn-dark" onClick={verifyReplacement}>
                    <i className="bi bi-check-lg"></i> Confirm
                  </button>
                </div>
              </div>
            </>
          ) : formType === "confirmation" ? (
            <>
              <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Replacement Order Placed!</h4>
                <p>
                  Your order has been confirmed for replacement. You can track your order by clicking <Link to={`/track/${order?.id}`}>here</Link>.
                </p>
                <hr />
                <p className="mb-0">We have sent an order confirmation on your email - {order?.customer.email}.</p>
              </div>
            </>
          ) : (
            <>
              <Alert theme="danger" title="Oops!" message="We are sorry. Something went wrong while placing your order. Please try again later." />
              <div className="mt-3 d-flex gap-3">
                <button className="btn btn-outline-dark" onClick={() => setFormType("replacement")}>
                  Back To Replacement
                </button>
              </div>
            </>
          )}
        </div>
        <div className="col-6">
          {order && (
            <>
              {order.order_details.map((item: any) => {
                return (
                  <div key={`orderitem-${item.product.id}`} className="checkout-item d-flex rounded border mb-2">
                    <NetworkImage src={item.product.photo_1} alt={item.id} />
                    <div className="d-flex flex-column flex-grow-1 px-3 py-2">
                      <span className="fw-bold">{toTitleCase(item.product.name)}</span>
                      <span>Size: {item.size}</span>
                      <span>Quantity: {item.quantity}</span>
                      <span>One Piece Price: {item.product.price}</span>
                    </div>
                    <div className="p-2 d-flex flex-column justify-content-between align-items-end">
                      <span className="fw-bold text-danger">Rs. {parseFloat(item.product.price) * item.quantity}</span>
                      <input className="form-check-input" type="checkbox" value={item.product.id} checked={replacementItemIds.includes(item.product.id)} onChange={handleItemChecked} />
                    </div>
                  </div>
                );
              })}
              <span>
                <strong>Note:</strong> All Item price are included with tax. Keep shopping.
              </span>
              <hr />
              <div className="d-flex justify-content-between mb-3">
                <span className="fw-bold">Total Payable Amount {!order?.is_canceled && <span className={`text-${order?.payment_status === PaymentStatus.COMPLETED ? "success" : "danger"}`}>({order?.payment_status === PaymentStatus.COMPLETED ? "Paid" : "Pending"})</span>}</span>
                <span className="fw-bold text-danger">Rs. {order?.payable_amount}</span>
              </div>
              <hr />
              {(!order?.is_canceled && formType === "replacement") && (
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span>Delivered on: {order.delivery_date}</span>
                  <button className="btn btn-dark" onClick={replaceOrder}>
                    <i className="bi bi-box-seam"></i> Replace Order
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
