import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";

export default function TrackOrderModal({ modalId }: { modalId: string }) {
  const navigate = useNavigate();
  const closeBtn = useRef<HTMLButtonElement | null>(null);
  const [orderNumber, setOrderNumber] = useState<string>("");

  const onTrackOrder = () => {
    closeBtn.current?.click();
    navigate(`/track/order_${orderNumber}`);
  }

  return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby={`${modalId}Label`} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bg-brand-light">
            <h1 className="modal-title fs-5" id={`${modalId}Label`}>
              Track Order
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="orderId" className="form-label">
                Enter your Order Number sent on your email
              </label>
              <input type="text" className="form-control" id="orderId" placeholder="Order Number" value={orderNumber} onChange={(e) => setOrderNumber(e.target.value)}/>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeBtn}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={onTrackOrder}>
              Track Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
