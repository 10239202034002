import { HttpClient } from "../../infra/http/http-client";

declare var Razorpay: any;

export class CheckoutService {
  public static instance = new CheckoutService();
  private http: HttpClient;
  private token: string = "";
  private order: any = null;

  private constructor() {
    this.http = HttpClient.instance;
  }

  public fetchOrder(orderId: string) {
    return this.http.get({ url: `orders/?id=${orderId}` });
  }

  public async checkout(details: any) {
    const response = await this.http.post({ url: "orders/checkout/", data: details });
    if (response.success) {
      this.token = response.data._t;
    }
    return response;
  }

  public async verifyCheckout(details: { otp: string }) {
    const response = await this.http.post({ url: "orders/verify-checkout/", data: details, headers: { tgo: this.token } });
    if (response.success) {
      this.token = response.data._t;
      this.order = response.data.order;
    }
    return response;
  }

  initiatePayment(paymentHandler: (response: any) => void) {
    return Razorpay({
      key: process.env.REACT_APP_PAYMENT_RAZORPAY_ID,
      amount: `${parseInt(this.order.payable_amount) * 100}`,
      currency: "INR",
      name: "The Girls",
      image: process.env.REACT_APP_LOGO_URL,
      description: `Payment of ₹${this.order.payable_amount}`,
      order_id: this.order.rzp_order_id,
      prefill: {
        name: this.order.customer.full_name,
        email: this.order.customer.email,
        phone: this.order.customer.phone,
      },
      notes: {
        product: "The Girls",
        customer_name: this.order.customer.full_name,
      },
      theme: { color: "#e1c2a3" },
      handler: paymentHandler,
    });
  }

  public verifyPayment(details: { rzp_order_id: string; rzp_payment_id: string; signature: string }) {
    return this.http.post({ url: "orders/verify-payment/", data: details, headers: { tgp: this.token } });
  }

  public cancelCheckout() {
    return this.http.post({ url: "orders/cancel-checkout/", headers: { tgp: this.token } });
  }

  public getOrder() {
    return this.order;
  }

  public clear() {
    this.token = "";
    this.order = null;
  }
}
