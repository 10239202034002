import "../styles/Category.css";
import React, { useEffect, useState } from "react";
import { CommonService } from "../services/common-service";
import NetworkImage from "./NetworkImage";
import { toTitleCase } from "../../infra/utils/utility";
import { Search } from "../models/search";
import { useNavigate } from "react-router";

export default function Category({ setSearchDetails } : { setSearchDetails: React.Dispatch<React.SetStateAction<Search>> }) {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<{ product: any[]; season: any[] } | null>(null);

  const searchForProductCategory = (productCategory: number) => {
    setSearchDetails({ query: "", productCategoryId: productCategory.toString(), seasonCategoryId: null });
    navigate('/search');
  };


  useEffect(() => {
    const fetchCategories = async () => {
      const response = await CommonService.instance.fetchCategory();
      if (response.success) {
        setCategories(response.data);
      }
    }

    fetchCategories();
  }, []);

  return (
    <div className="bg-brand-light mt-3 pb-5">
      <div className="text-center py-5">
        <span className="fw-bold fs-4 ff-brand">Categories</span>
      </div>
      <div className="container d-flex justify-content-center flex-wrap gap-5">
        {categories &&
          categories.product.map((category, index) => (
            <div key={`productcategory-${category.name}-${index}`} className="d-flex flex-column align-items-center gap-5" onClick={() => searchForProductCategory(category.id)}>
              <NetworkImage className="rounded-circle category-img" src={category.photo} />
              <span className="card-title ff-brand">{toTitleCase(category.name)}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
