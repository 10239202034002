export enum OrderStatus {
    PREPARING = 'PREPARING',
    DISPATCHED = 'DISPATCHED',
    DELIVERED = 'DELIVERED'
}

export enum ReplaceStatus {
    PREPARING = 'PREPARING',
    DISPATCHED = 'DISPATCHED',
    DELIVERED_N_PICKUP = 'DELIVERED_N_PICKUP'
}

export enum PaymentStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}