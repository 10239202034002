import React from "react";

export default function Spinner({ marginTop } : { marginTop: string}) {
  return (
    <div className="text-center" style={{ marginTop: marginTop }}>
      <div className="spinner-border text-dark" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
