import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./app/pages/HomePage";
import Navbar from "./app/components/Navbar";
import Marquee from "./app/components/Marquee";
import { CommonService } from "./app/services/common-service";
import SearchPage from "./app/pages/SearchPage";
import ProductPage from "./app/pages/ProductPage";
import Footer from "./app/components/Footer";
import AboutPage from "./app/pages/AboutPage";
import ContactPage from "./app/pages/ContactPage";
import TrackPage from "./app/pages/TrackPage";
import ReplacePage from "./app/pages/ReplacePage";
import PrivacyPolicyPage from "./app/pages/PrivacyPolicyPage";
import { Search } from "./app/models/search";
import CartList from "./app/components/CartList";
import CheckoutPage from "./app/pages/CheckoutPage";
import TrackOrderModal from "./app/components/TrackOrderModal";
import ReplaceOrderModal from "./app/components/ReplaceOrderModal";

function App() {
  const [messages, setMessages] = useState<string[] | null>(null);
  const [categories, setCategories] = useState<{ product: any[]; season: any[] } | null>(null);
  const [searchDetails, setSearchDetails] = useState<Search>({ query: "", productCategoryId: null, seasonCategoryId: null });

  useEffect(() => {
    const fetchInitials = async () => {
      const response1 = await CommonService.instance.fetchMessages();
      if (response1.success) {
        setMessages(response1.data.messages.map((message: any) => message.text));
      }

      const response2 = await CommonService.instance.fetchCategory();
      if (response2.success) {
        setCategories(response2.data);
      }
    };

    fetchInitials();
  }, []);

  return (
    <div className="app">
      <BrowserRouter>
        {messages && <Marquee messages={messages} />}
        <Navbar categories={categories} setSearchDetails={setSearchDetails} />
        <TrackOrderModal modalId="trackOrder" />
        <ReplaceOrderModal modalId="replaceOrder" />
        <CartList />
        <Routes>
          <Route index element={<HomePage setSearchDetails={setSearchDetails}/>} />
          <Route path="product/:productId" element={<ProductPage />} />
          <Route path="search" element={<SearchPage categories={categories} navbarSearchDetails={searchDetails} />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="track/:orderId" element={<TrackPage />} />
          <Route path="replace/:orderId" element={<ReplacePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy" element={<PrivacyPolicyPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
