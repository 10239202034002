import React, { useEffect } from "react";

export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  return (
    <div className="container my-5">
      <div className="mb-5" id="privacy">
        <h1>Privacy Policy</h1>
        <div className="mt-5">
          <p>At The Girls by Avni Gupta, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website or make a purchase.</p>
          <ul>
            <li>
              <strong>Information Collection:</strong> We collect personal information such as your name, email address, billing/shipping address, and payment details when you place an order. We may also collect non-personal data to enhance your experience on our site.
            </li>
            <li>
              <strong>Use of Information:</strong> Your personal information is used to process orders, send you updates, and improve our services. We do not share your information with third parties, except as necessary to fulfill your order (e.g., shipping partners).
            </li>
            <li>
              <strong>Data Security:</strong> We take the security of your data seriously and implement industry-standard measures to protect your information.
            </li>
            <li>
              <strong>Cookies:</strong> Our website uses cookies to improve your browsing experience. You can adjust your cookie preferences in your browser settings.
            </li>
            <li>
              <strong>Third-Party Links:</strong> Our site may contain links to third-party websites. We are not responsible for the privacy practices of those websites.
            </li>
            <li>
              <strong>Changes to Privacy Policy:</strong> We reserve the right to update this policy. Any changes will be posted on this page.
            </li>
          </ul>
          <p>By using our website, you consent to our Privacy Policy.</p>
        </div>
      </div>
      <div className="mb-5" id="termsS">
        <h1>Terms & Conditions</h1>
        <div className="mt-5">
          <p>Welcome to The Girls by Avni Gupta. By accessing or making a purchase from our website, you agree to the following terms and conditions:</p>
          <ul>
            <li>
              <strong>Product Descriptions:</strong> We strive to provide accurate product descriptions and images. However, slight variations in color or fabric may occur due to photography or device display settings.
            </li>
            <li>
              <strong>Order Processing:</strong> Once an order is placed, we will process it promptly. You will receive a confirmation email with your order details and tracking information.
            </li>
            <li>
              <strong>Payment:</strong> All payments are processed securely. We accept major credit/debit cards and other online payment methods.
            </li>
            <li>
              <strong>Shipping:</strong> We ship within India, and international shipping may be available on request. Shipping times may vary based on location and availability. Shipping delays caused by external factors (e.g., courier delays) are beyond our control.
            </li>
            <li>
              <strong>Cancellations & Refunds:</strong> We allow order cancellations before the item has been shipped. Upon cancellation, a full refund will be issued. Once the order has shipped, we are unable to process refunds.
            </li>
            <li>
              <strong>Replacement Policy:</strong> If you are not satisfied with your purchase, you may request a replacement within 4 days of receiving the product. Please note that replacements can only be processed once per order. The item must be in unused condition and with all original packaging intact.
            </li>
            <li>
              <strong>Intellectual Property:</strong> All content on our website, including images, designs, and text, is the property of The Girls by Avni Gupta. Unauthorized use or reproduction is prohibited.
            </li>
            <li>
              <strong>Liability:</strong> While we ensure the highest quality, we are not liable for any damages or losses that may result from the use of our products beyond their intended purpose.
            </li>
            <li>
              <strong>Changes to Terms:</strong> We may update our Terms & Conditions from time to time. Continued use of our website constitutes your acceptance of the updated terms.
            </li>
          </ul>
          <p>By shopping with The Girls by Avni Gupta, you agree to our policies and understand the scope of our refund and replacement terms.</p>
        </div>
      </div>
    </div>
  );
}
