import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router";
import NetworkImage from "../components/NetworkImage";
import { toTitleCase } from "../../infra/utils/utility";
import states from "../../assets/json/states.json";
import { isEmail, isPhone } from "../../infra/utils/validators";
import { CheckoutService } from "../services/checkout-service";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { clearCart } from "../features/cartSlice";
import "../styles/CheckoutPage.css";

export default function CheckoutPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart);
  const [error, setError] = useState<string | null>(null);
  const [formType, setFormType] = useState<"checkout" | "verification" | "payment" | "confirmation" | "failure">("checkout");
  const [checkoutForm, setCheckoutForm] = useState({ first_name: "", last_name: "", email: "", phone: "", address: "", city: "", state: "NULL", pincode: "", message: "" });
  const [verifyForm, setVerifyForm] = useState({ otp: "" });

  const onCheckoutInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setCheckoutForm({ ...checkoutForm, [event.target.name]: event.target.value });
  };

  const onVerifyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyForm({ otp: event.target.value });
  };

  const getTotalPayableAmount = () => {
    let total = 0;
    cart.items.forEach((item) => {
      total += item.quantity * parseFloat(item.product.price);
    });
    return total;
  };

  const validateCheckoutForm = () => {
    if (checkoutForm.first_name.length === 0) return "Requires First Name";
    if (checkoutForm.last_name.length === 0) return "Requires Last Name";
    if (!isEmail(checkoutForm.email)) return "Invalid Email";
    if (!isPhone(checkoutForm.phone)) return "Invalid Phone Number";
    if (checkoutForm.address.length === 0) return "Requires Delivery Address";
    if (checkoutForm.city.length === 0) return "Requires City";
    if (checkoutForm.state === "NULL") return "Requires State";
    if (checkoutForm.pincode.length === 0) return "Requires Pincode";
    if (checkoutForm.message.length > 500) return "Message should not exceed 200 characters";
    return null;
  };

  const checkout = () => {
    const error = validateCheckoutForm();
    if (error) {
      setError(error);
    } else {
      setError(null);

      CheckoutService.instance
        .checkout({
          ...checkoutForm,
          cart_items: cart.items.map((item) => `${item.product.id}|${item.size}|${item.quantity}`).join(","),
        })
        .then((response) => {
          if (response.success) {
            setFormType("verification");
          } else {
            setError("Unable to Place your order.");
          }
        });
    }
  };

  const validateVerifyForm = () => {
    if (verifyForm.otp.length !== 6) return "Invalid otp, must be 6 digits.";
    return null;
  };

  const verifyCheckout = () => {
    const error = validateVerifyForm();
    if (error) {
      setError(error);
    } else {
      setError(null);

      CheckoutService.instance
        .verifyCheckout({
          otp: verifyForm.otp,
        })
        .then((response) => {
          if (response.success) {
            setFormType("payment");
            setTimeout(initiatePayment, 500);
          } else {
            setError("Invalid OTP or expired. Try again.");
          }
        });
    }
  };

  const initiatePayment = () => {
    const rzpDialog = CheckoutService.instance.initiatePayment((response) => {
      CheckoutService.instance
        .verifyPayment({
          rzp_order_id: response.razorpay_order_id,
          rzp_payment_id: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        })
        .then((response) => {
          if (response.success) {
            setFormType("confirmation");
            dispatch(clearCart());
          } else {
            setFormType("failure");
          }
        });
    });
    rzpDialog.open();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="container mt-5">
      <span className="h3 ff-brand">Checkout - Place Order</span>
      <p>By shopping with The Girls, you agree to our policies and understand the scope of our refund and replacement terms.</p>
      {error && (
        <div className="mt-3">
          <Alert theme="danger" message={error} title="Oops!" />
        </div>
      )}
      <div className="row my-5">
        <div className="col-md-6">
          {formType === "checkout" ? (
            <>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="firstname" className="form-label">
                    First Name
                  </label>
                  <input type="text" className="form-control" id="firstname" placeholder="First Name" name="first_name" onChange={onCheckoutInputChange} value={checkoutForm.first_name} />
                </div>
                <div className="col">
                  <label htmlFor="lastname" className="form-label">
                    Last Name
                  </label>
                  <input type="text" className="form-control" id="lastname" placeholder="Last Name" name="last_name" onChange={onCheckoutInputChange} value={checkoutForm.last_name} />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input type="email" className="form-control" id="email" placeholder="someone@example.com" name="email" onChange={onCheckoutInputChange} value={checkoutForm.email} />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone no.
                </label>
                <input type="number" className="form-control" id="phone" placeholder="xxxxx-xxxxx" name="phone" onChange={onCheckoutInputChange} value={checkoutForm.phone} />
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Delivery Address
                    </label>
                    <input type="text" className="form-control" id="address" placeholder="Your Delivery Address" name="address" onChange={onCheckoutInputChange} value={checkoutForm.address} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <input type="text" className="form-control" id="city" placeholder="Your City" name="city" onChange={onCheckoutInputChange} value={checkoutForm.city} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select className="form-select" aria-label="State" id="state" name="state" onChange={onCheckoutInputChange} value={checkoutForm.state}>
                      <option value={"NULL"} selected>
                        Select State
                      </option>
                      {Object.values(states).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="pincode" className="form-label">
                      Pincode
                    </label>
                    <input type="number" className="form-control" id="pincode" placeholder="xxxxxx" min={6} max={6} name="pincode" onChange={onCheckoutInputChange} value={checkoutForm.pincode} />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Leave a message <span className="text-secondary">(optional)</span>
                </label>
                <textarea className="form-control" id="phone" rows={3} name="message" onChange={onCheckoutInputChange} value={checkoutForm.message}></textarea>
              </div>
            </>
          ) : formType === "verification" ? (
            <>
              <div className="mb-3">
                <label htmlFor="otp" className="form-label">
                  Enter the OTP sent on your email - {checkoutForm.email}
                </label>
                <input type="number" className="form-control" id="otp" placeholder="xxxxxx" min={6} max={6} name="otp" onChange={onVerifyInputChange} value={verifyForm.otp} />
              </div>
              <div className="mb-3 d-flex justify-content-between">
                <div>
                  {error && (
                    <button className="btn btn-outline-dark" onClick={checkout}>
                      <i className="bi bi-arrow-clockwise"></i> Resent OTP
                    </button>
                  )}
                </div>
                <div>
                  <button className="btn btn-dark" onClick={verifyCheckout}>
                    <i className="bi bi-check-lg"></i> Confirm
                  </button>
                </div>
              </div>
            </>
          ) : formType === "payment" ? (
            <>
              <Alert theme="success" title="Payment" message="Initiate payment for your order on one click secured by RazorPay." />
              <div className="mt-3 d-flex gap-3">
                <button className="btn btn-outline-dark" onClick={() => setFormType("checkout")}>
                  Back To Checkout
                </button>
                <button className="btn btn-success" onClick={() => initiatePayment()}>
                  Pay Now
                </button>
              </div>
            </>
          ) : formType === "confirmation" ? (
            <>
              <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Order Placed!</h4>
                <p>
                  Your order has been confirmed and placed. You can track your order by clicking <Link to={`/track/${CheckoutService.instance.getOrder().id}`}>here</Link>.
                </p>
                <hr />
                <p className="mb-0">We have sent an order confirmation on your email - {checkoutForm.email}.</p>
              </div>
            </>
          ) : (
            <>
              <Alert theme="danger" title="Oops!" message="We are sorry. Something went wrong while placing your order. Please try again later." />
              <div className="mt-3 d-flex gap-3">
                <button className="btn btn-outline-dark" onClick={() => setFormType("checkout")}>
                  Back To Checkout
                </button>
              </div>
            </>
          )}
        </div>
        <div className="col-md-6">
          {cart.items.length > 0 ? (
            <>
              {cart.items.map((item) => {
                return (
                  <div key={`cartitem-${item.id}`} className="checkout-item d-flex rounded border mb-2">
                    <NetworkImage src={item.product.photo_1} alt={item.id} />
                    <div className="d-flex flex-column flex-grow-1 px-3 py-2">
                      <span className="fw-bold">{toTitleCase(item.product.name)}</span>
                      <span>Size: {item.size}</span>
                      <span>Quantity: {item.quantity}</span>
                      <span>One Piece Price: {item.product.price}</span>
                    </div>
                    <div className="p-2">
                      <span className="fw-bold text-danger">Rs. {parseFloat(item.product.price) * item.quantity}</span>
                    </div>
                  </div>
                );
              })}
              <span>
                <strong>Note:</strong> All Item price are included with tax. Keep shopping.
              </span>
              <hr />
              <div className="d-flex justify-content-between mb-3">
                <span className="fw-bold">Total Payable Amount</span>
                <span className="fw-bold text-danger">Rs. {getTotalPayableAmount()}</span>
              </div>
              {formType === "checkout" && (
                <>
                  <hr />
                  <div className="mb-3 text-end">
                    <button className="btn btn-dark" onClick={checkout}>
                      <i className="bi bi-box-seam"></i> Place Order
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="text-center">
                <div className="mb-3">
                  <span>Your Cart is empty. Keep shopping.</span>
                </div>
                <button className="btn btn-dark" onClick={() => navigate('/')}>Back to Shopping</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
