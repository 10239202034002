import { HttpClient } from "../../infra/http/http-client";

export class CommonService {
    public static instance = new CommonService();
    private http: HttpClient;

    private messages: any = null;
    private frontPhotos: any = null;
    private categories: any = null;
    
    private constructor() {
        this.http = HttpClient.instance;
    }

    fetchMessages() {
        if (this.messages) {
            return Promise.resolve(this.messages);
        }
        return this.http.get({ url: 'entity/message/' });
    }

    fetchFrontPhotos() {
        if (this.frontPhotos) {
            return Promise.resolve(this.frontPhotos);
        }
        return this.http.get({ url: 'entity/front-photo/' });
    }

    fetchCategory() {
        if (this.categories) {
            return Promise.resolve(this.categories);
        }
        return this.http.get({ url: 'products/categories/' });
    }
}