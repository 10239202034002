import React from "react";
import NetworkImage from "./NetworkImage";
import { CartItem } from "../models/cart";
import { useAppDispatch } from "../../redux/hooks";
import "../styles/CartItem.css";
import { removeFromCart, updateItemQuantity } from "../features/cartSlice";

export default function CartProductItem({ item }: { item: CartItem }) {
  const dispatch = useAppDispatch();

  const setQuantity = (quantity: number) => {
    dispatch(updateItemQuantity({ itemId: item.id, quantity: quantity }));
  };

  const updateQuantity = (type: "inc" | "dec") => {
    if (type === "inc" && item.quantity < item.product.stock_count) {
      setQuantity(item.quantity + 1);
    } else if (type === "dec" && item.quantity > 1) {
      setQuantity(item.quantity - 1);
    }
  };

  const removeProductFromCart = () => {
    dispatch(removeFromCart({ itemId: item.id}));
  }

  return (
    <div className="card mb-3 w-100">
      <div className="row g-0">
        <div className="col-md-4">
          <NetworkImage src={item.product.photo_1} className="img-fluid rounded-start" alt={item.product.name} />
        </div>
        <div className="col-md-8">
          <div className="card-body d-flex flex-column">
            <div>
              <span className="fw-bold ff-brand">{item.product.name}</span>
              <span className="ci-desc">{item.product.description}</span>
              <span className="text-secondary">Size: {item.size}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="input-group input-group-sm">
                <button className="btn btn-light" type="button" onClick={() => updateQuantity("dec")}>
                      <i className="bi bi-dash-lg"></i>
                    </button>
                    <input type="number" className="form-control" value={item.quantity} min={1} max={item.product.stock_count} onChange={() => {}}/>
                    <button className="btn btn-light" type="button" onClick={() => updateQuantity("inc")}>
                      <i className="bi bi-plus-lg"></i>
                    </button>
                </div>
              </div>
              <div>
                <button className="btn btn-sm btn-danger" onClick={removeProductFromCart}><i className="bi bi-trash"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
