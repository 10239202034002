import "../styles/CartList.css";
import React, { useRef } from "react";
import { useAppSelector } from "../../redux/hooks";
import CartItem from "./CartProductItem";
import { useNavigate } from "react-router";

export default function CartList() {
  const navigate = useNavigate();
  const cart = useAppSelector((state) => state.cart);
  const closeBtn = useRef<HTMLButtonElement>(null);

  const onCheckout = () => {
    closeBtn.current?.click();
    navigate("/checkout");
  };

  return (
    <div className="offcanvas offcanvas-end" tabIndex={-1} id="cartOffCanvas" aria-labelledby="cartOffCanvasLabel">
      <div className="offcanvas-header bg-brand-light">
        <h5 className="offcanvas-title" id="cartOffCanvasLabel">
          <i className="bi bi-bag"></i> My Cart
        </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ref={closeBtn}></button>
      </div>
      <div className="offcanvas-body position-relative">
        {cart.items.length > 0 ? (
          cart.items.map((item, index) => {
            return <CartItem key={`cartlist-${item.product.id}-${index}`} item={item} />;
          })
        ) : (
          <div className="text-center">
            <span className="mt-5">Your Cart is empty. Keep shopping.</span>
          </div>
        )}
        {cart.items.length > 0 && (
          <div className="checkout-footer position-fixed bottom-0 bg-white p-3 border-top text-end">
            <button className="btn btn-success" onClick={() => onCheckout()}>
              <i className="bi bi-check2-circle"></i> Checkout
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
