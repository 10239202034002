import React, { useEffect, useState } from "react";
import { Product } from "../models/product";
import { ProductService } from "../services/product-service";
import ProductItem from "../components/ProductItem";
import ProductViewModal from "../components/ProductViewModal";
import { Search } from "../models/search";
import { toTitleCase } from "../../infra/utils/utility";
import Spinner from "../components/Spinner";

export default function SearchPage({ categories, navbarSearchDetails }: { categories: { product: any[]; season: any[] } | null; navbarSearchDetails: Search }) {
  const [isSearching, setSearching] = useState(true);
  const [searchProducts, setSearchProducts] = useState<Product[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [searchDetails, setSearchDetails] = useState<Search>(navbarSearchDetails);

  const fetchSearchProducts = async () => {
    setSearchProducts(null);
    setSearching(true);
    const response = await ProductService.instance.search(searchDetails);
    if (response.success) {
      setSearchProducts(response.data.products);
      setSearching(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onSearchFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    fetchSearchProducts();
  };

  const makeCommaSeparatedString = (previousValue: string | null, currentValue: string): string | null => {
    if (previousValue === null) {
      return currentValue.toString();
    }

    if (previousValue.includes(currentValue)) {
      currentValue = previousValue
        .split(",")
        .filter((id) => id !== currentValue)
        .join(",");
    } else {
      currentValue = previousValue ? `${previousValue},${currentValue}` : currentValue;
    }

    if (currentValue === "") {
      return null;
    }
    return currentValue;
  };

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    let value: any = event.currentTarget.value;
    if (event.currentTarget.name === "productCategoryId") {
      value = makeCommaSeparatedString(searchDetails.productCategoryId, value);
    } else if (event.currentTarget.name === "seasonCategoryId") {
      value = makeCommaSeparatedString(searchDetails.seasonCategoryId, value);
    }
    setSearchDetails({ ...searchDetails, [event.currentTarget.name]: value });
  };

  const onSelectProduct = (event: React.MouseEvent<HTMLButtonElement>, product: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedProduct(product);
  };

  useEffect(() => {
    fetchSearchProducts();
  }, []);

  return (
    <div className="bg-brand-light pt-5">
      <div className="container d-flex flex-column align-items-center gap-3">
        <label className="form-label fs-3 ff-brand">Search what you wear...</label>
        <div className="input-group mb-3 w-75">
          <input type="text" className="form-control" id="searhQuery" placeholder="Search for cloths..." name="query" value={searchDetails.query} onChange={handleInputChange} />
          <button className="btn btn-dark" type="button" id="button-addon2" onClick={onSearchFilter}>
            <i className="bi bi-search"></i>
          </button>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-2">
            <div className="p-3">
              <div className="mb-3">
                <span className="form-label fs-3">Filters</span>
              </div>
              <div className="mb-3">
                <label className="form-label">Collections</label>
                {categories &&
                  categories.product.map((category, index) => (
                    <div key={`productcategory-${category.id}-${index}`} className="d-flex gap-3">
                      <input type="checkbox" className="form-check-input" id={`productType${category.id}`} name="productCategoryId" value={category.id} onChange={handleInputChange} checked={searchDetails.productCategoryId?.includes(category.id.toString())} />
                      <label className="form-check-label" htmlFor={`productType${category.id}`}>
                        {toTitleCase(category.name)}
                      </label>
                    </div>
                  ))}
              </div>
              <div className="mb-3">
                <label className="form-label">Shop by Season</label>
                {categories &&
                  categories.season.map((category, index) => (
                    <div key={`seasoncategory-${category.id}-${index}`} className="d-flex gap-3">
                      <input type="checkbox" className="form-check-input" id={`season${category.id}`} name="seasonCategoryId" value={category.id} onChange={handleInputChange} checked={searchDetails.seasonCategoryId?.includes(category.id.toString())} />
                      <label className="form-check-label" htmlFor={`season${category.id}`}>
                        {toTitleCase(category.name)}
                      </label>
                    </div>
                  ))}
              </div>
              <div className="mb-3">
                <button className="btn btn-dark w-100" onClick={onSearchFilter}>
                  Filter
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-10 pb-5">
            {searchProducts && searchProducts.length > 0 ? (
              <>
                <div className="row row-cols-auto gap-3">
                  {searchProducts.map((product, index) => (
                    <div key={`searchproduct-${product.id}-${index}`} className="col">
                      <ProductItem modalId="SearchProduct" product={product} onSelect={onSelectProduct} />
                    </div>
                  ))}
                </div>
                <ProductViewModal modalId="SearchProduct" product={selectedProduct} />
              </>
            ) : isSearching ? (
              <Spinner marginTop="100px" />
            ) : (
              <div className="text-center">No Results Found...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
