import { HttpClient } from "../../infra/http/http-client";

export class OrderService {
    public static instance = new OrderService();
    private http: HttpClient;
    private token: string = '';
  
    private constructor() {
      this.http = HttpClient.instance;
    }
  
    public fetchOrder(orderId: string) {
      return this.http.get({ url: `orders/order/?id=${orderId}` });
    }

    public async replaceOrder(orderId: string, details: { product_items: string }) {
      const response = await this.http.post({ url: `orders/replace/?id=${orderId}`, data: details });
      if (response.success) {
        this.token = response.data._t;
      }
      return response;
    }

    public verifyReplacement(details : { otp: string }) {
      return this.http.post({ url: 'orders/verify-replace/', data: details, headers: { tgo: this.token } });
    }
}