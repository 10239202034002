import React, { useEffect, useRef, useState } from "react";
import NetworkImage from "./NetworkImage";
import "../styles/ProductViewModal.css";
import { Link } from "react-router-dom";
import { id, toTitleCase } from "../../infra/utils/utility";
import { Product } from "../models/product";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addToCart } from "../features/cartSlice";

export default function ProductViewModal({ modalId, product }: { modalId: string; product: Product | null }) {
  const dispatch = useAppDispatch();
  const cart = useAppSelector((state) => state.cart);
  const closeBtn = useRef<HTMLButtonElement>(null);
  const [productPhoto, setProductPhoto] = useState<string>(product ? product.photo_1 : "");
  const [selectedSize, setSelectedSize] = useState<string>(product ? product.sizes.split(",")[0] : "S");
  const [selectedQuantity, setQuantity] = useState(1);

  const addProductToCart = () => {
    dispatch(addToCart({ id: id(), product: product!, size: selectedSize, quantity: selectedQuantity }));
  };

  const updateQuantity = (type: "inc" | "dec") => {
    if (type === "inc" && selectedQuantity < product!.stock_count) {
      setQuantity(selectedQuantity + 1);
    } else if (type === "dec" && selectedQuantity > 1) {
      setQuantity(selectedQuantity - 1);
    }
  };

  useEffect(() => {
    if (product) {
      setProductPhoto(product.photo_1);
    }
  }, [setProductPhoto, product]);

  return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby={`${modalId}Label`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          {product && (
            <>
              <div className="modal-header bg-brand-light">
                <h1 className="modal-title fs-5 ff-brand" id={`${modalId}Label`}>
                  {toTitleCase(product.name)}
                </h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body p-0">
                <div className="row">
                  <div className="col-md-6">
                    <NetworkImage className="img-fluid" src={productPhoto} alt={product.name} />
                  </div>
                  <div className="col-md-6">
                    <h3 className="mt-3 mb-1 ff-brand">{toTitleCase(product.name)}</h3>
                    <div className="mb-3 d-flex align-items-center gap-3">
                      <span className="fs-5 text-danger">Rs. {product.price}</span>
                      {product.discount_percent > 0 && <span className="fs-5 text-decoration-line-through">Rs. {product.total_price}</span>}
                      {product.is_on_sale && <span className="badge bg-danger">SALE</span>}
                    </div>
                    <div className="mb-3">
                      <p className="pvm-desc mb-1">{product.description}</p>
                      <Link className="link-offset-2 link-underline link-underline-opacity-100" to={`/product/${product.id}`} onClick={() => closeBtn.current?.click()}>
                        View Details
                      </Link>
                    </div>
                    <div className="mb-3">
                      <i className="bi bi-box-seam"></i> <span>Only {product.stock_count} Left</span>
                    </div>
                    <div className="mb-3">
                      <span>Select size</span>
                      <div className="d-flex flex-wrap gap-2">
                        {product.sizes.split(",").map((size: string, index: number) => {
                          const key = size + index;
                          return (
                            <div key={key} className={`d-flex justify-content-center align-items-center border rounded pvm-size cursor-pointer ${selectedSize === size ? "badge bg-dark border-black" : ""} `} onClick={() => setSelectedSize(size)}>
                              <span className="px-1">{size}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mb-3">
                      <span>Quantity</span>
                      <div className="input-group mb-3 w-50">
                        <button className="btn btn-light" type="button" onClick={() => updateQuantity("dec")}>
                          <i className="bi bi-dash-lg"></i>
                        </button>
                        <input type="number" className="form-control" value={selectedQuantity} min={1} max={product.stock_count} onChange={() => {}} />
                        <button className="btn btn-light" type="button" onClick={() => updateQuantity("inc")}>
                          <i className="bi bi-plus-lg"></i>
                        </button>
                      </div>
                    </div>
                    {cart.items.find((item) => item.product.id === product.id) && (
                      <button type="button" className="btn btn-success" data-bs-toggle="offcanvas" data-bs-target="#cartOffCanvas" aria-controls="offcanvasRight" onClick={() => closeBtn.current?.click()}>
                        <i className="bi bi-bag-check"></i> View in Cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <div className="d-flex gap-2">
                  <div className={`pvm-img-select border ${productPhoto === product.photo_1 ? "border-black" : ""}`} onClick={() => setProductPhoto(product.photo_1)}>
                    <NetworkImage className="img-fluid pvm-img" src={product.photo_1} alt={product.name} />
                  </div>
                  {product.photo_2 && (
                    <div className={`pvm-img-select border ${productPhoto === product.photo_2 ? "border-black" : ""}`} onClick={() => setProductPhoto(product.photo_2!)}>
                      <NetworkImage className="img-fluid pvm-img" src={product.photo_2} alt={product.name} />
                    </div>
                  )}
                  {product.photo_3 && (
                    <div className={`pvm-img-select border ${productPhoto === product.photo_3 ? "border-black" : ""}`} onClick={() => setProductPhoto(product.photo_3!)}>
                      <NetworkImage className="img-fluid pvm-img" src={product.photo_3} alt={product.name} />
                    </div>
                  )}
                </div>
                <div className="d-flex gap-3">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeBtn}>
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => addProductToCart()}>
                    <i className="bi bi-bag-plus"></i> Add to Cart
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
