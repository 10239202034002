import React, { useEffect, useState } from "react";
import { ProductService } from "../services/product-service";
import ProductItem from "./ProductItem";
import ProductViewModal from "./ProductViewModal";
import { Product } from "../models/product";

export default function SuggestedProduct({ query, productCategoryId, seasonCategoryId }: { query: string; productCategoryId: string | null; seasonCategoryId: string | null }) {
  const [suggestedProducts, setSuggestedProducts] = useState<Product[] | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const onSelectProduct = (event: React.MouseEvent<HTMLButtonElement>, product: Product) => {
    event.stopPropagation();
    setSelectedProduct(product);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      const response = await ProductService.instance.search({ query, productCategoryId, seasonCategoryId });
      if (response.success) {
        setSuggestedProducts(response.data.products);
      }
    };

    fetchSuggestions();
  }, []);

  return (
    <div className="container mt-5">
      {suggestedProducts && suggestedProducts.length > 0 && (
        <>
          <div className="text-center">
            <span className="fw-bold fs-4 ff-brand">You Might Also Like</span>
          </div>
          <div className="row row-cols-auto gap-3 mt-5">
            {suggestedProducts.map((product, index) => (
              <div className="col">
                <ProductItem key={`suggestedproduct-${product.id}-${index}`} modalId="SuggestedProduct" product={product} onSelect={onSelectProduct} />
              </div>
            ))}
          </div>
          <ProductViewModal modalId="SuggestedProduct" product={selectedProduct} />
        </>
      )}
    </div>
  );
}
