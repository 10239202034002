import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import SaleProduct from '../components/SaleProduct'
import Category from '../components/Category'
import { Search } from '../models/search'

export default function HomePage({ setSearchDetails } : { setSearchDetails: React.Dispatch<React.SetStateAction<Search>> }) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  return (
    <div>
      <Banner/>
      <SaleProduct/>
      <Category setSearchDetails={setSearchDetails}/>
    </div>
  )
}
