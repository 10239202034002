import "../styles/Product.css";
import React, { useState } from "react";
import NetworkImage from "./NetworkImage";
import { toTitleCase } from "../../infra/utils/utility";
import { Product } from "../models/product";
import { useNavigate } from "react-router";

export default function ProductItem({ modalId, product, onSelect }: { modalId: string; product: Product; onSelect: (e: React.MouseEvent<HTMLButtonElement>, product: Product) => void }) {
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(product.photo_1);

  const handleMouseEnter = () => {
    if (product.photo_2) {
      setPhoto(product.photo_2);
    }
  };

  const handleMouseLeave = () => {
    setPhoto(product.photo_1);
  };

  const onItemClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="card product-card position-relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onItemClick}>
      <NetworkImage className="card-img-top product-card-img" src={photo} />
      <div className="position-absolute product-discount-badge">{product.discount_percent > 0 && <span className="bg-danger text-white p-2 rounded">{product.discount_percent}% OFF</span>}</div>
      <div className="position-absolute product-select-option">
        <button className="btn btn-light" data-bs-toggle="modal" data-bs-target={`#${modalId}`} onClick={(e) => onSelect(e, product)}>
          Select Option
        </button>
      </div>
      <div className="card-body">
        <span className="card-title ff-brand">{toTitleCase(product.name)}</span>
      </div>
      <div className="card-footer text-body-secondary d-flex gap-2">
        <span className="text-danger">Rs. {product.price}</span>
        {product.discount_percent > 0 && <span className="text-decoration-line-through">Rs. {product.total_price}</span>}
      </div>
    </div>
  );
}
