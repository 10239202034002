import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { OrderService } from "../services/order-service";
import NetworkImage from "../components/NetworkImage";
import { toTitleCase } from "../../infra/utils/utility";
import { OrderStatus, PaymentStatus, ReplaceStatus } from "../../constants/constants";
import Alert from "../components/Alert";

export default function TrackPage() {
  const { orderId } = useParams();
  const [details, setDetails] = useState<any>(null);

  const getOrderStatusPercent = () => {
    let percent = 0;
    if (details?.order) {
      switch (details.order.order_status) {
        case OrderStatus.PREPARING:
          percent = 33;
          break;
        case OrderStatus.DISPATCHED:
          percent = 70;
          break;
        case OrderStatus.DELIVERED:
          percent = 100;
          break;
        default:
          break;
      }
    }
    return percent;
  };

  const getReplaceOrderStatusPercent = () => {
    let percent = 0;
    if (details?.order_replace) {
      switch (details.order_replace.replace_status) {
        case ReplaceStatus.PREPARING:
          percent = 33;
          break;
        case ReplaceStatus.DISPATCHED:
          percent = 70;
          break;
        case ReplaceStatus.DELIVERED_N_PICKUP:
          percent = 100;
          break;
        default:
          break;
      }
    }
    return percent;
  };

  const getReplaceOrderProductIds = () => {
    if (details?.order_replace && details.order_replace.replace_details) {
      return details.order_replace.replace_details.map((item: any) => item.product.id);
    }
    return [];
  }

  useEffect(() => {
    const fetchOrder = async () => {
      if (orderId) {
        const response = await OrderService.instance.fetchOrder(orderId);
        if (response.success) {
          setDetails(response.data);
        }
      }
    };

    fetchOrder();
  }, [orderId]);

  return (
    <div className="container mt-5">
      <span className="h3 ff-brand">Order Tracking - {details?.order.id.substring(6)}</span>
      <div className="row my-5">
        <div className="col-md-6">
          {details?.order.is_canceled ? (
            <Alert theme="danger" title="Oops!" message="This order was canceled. Your amount will be refunded or may already be refunded. Contact customer support if not. Happy Shopping!" />
          ) : (
            <>
              <div>
                <span>Order Progress...</span>
                <div className="progress mb-1" role="progressbar" aria-valuenow={getOrderStatusPercent()} aria-valuemin={0} aria-valuemax={100}>
                  <div className="progress-bar bg-success" style={{ width: `${getOrderStatusPercent()}%` }}>
                    {details?.order.order_status}
                  </div>
                </div>
                <div className="mb-3">{details?.order.order_status === OrderStatus.DELIVERED ? <span>Delivered on: {details?.order.delivery_date}</span> : <span>Estimated delivery date: {details?.order.estimated_delivery_date}</span>}</div>
              </div>
              {details?.order_replace && (
                <div>
                  <span>Replacement Progress...</span>
                  <div className="progress mb-1" role="progressbar" aria-valuenow={getReplaceOrderStatusPercent()} aria-valuemin={0} aria-valuemax={100}>
                    <div className="progress-bar bg-primary" style={{ width: `${getReplaceOrderStatusPercent()}%` }}>
                      {details?.order_replace.replace_status}
                    </div>
                  </div>
                  <div className="mb-3">{details?.order_replace.replace_status === ReplaceStatus.DELIVERED_N_PICKUP ? <span>Delivered on: {details?.order_replace.delivery_date}</span> : <span>Estimated delivery date: {details?.order_replace.estimated_delivery_date}</span>}</div>
                </div>
              )}
            </>
          )}
          <div className="mb-3">
            <p>
              <strong>Customer Name: </strong> {details?.order.customer.full_name}
            </p>
            <p>
              <strong>Delivery Address: </strong> {details?.order.delivery_address.address}
            </p>
            <p>
              <strong>City: </strong> {details?.order.delivery_address.city}
            </p>
            <p>
              <strong>State: </strong> {details?.order.delivery_address.state}
            </p>
            <p>
              <strong>Pincode: </strong> {details?.order.delivery_address.pincode}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          {details && (
            <>
              {details.order.order_details.map((item: any) => {
                return (
                  <div key={`orderitem-${item.product.id}`} className="checkout-item d-flex rounded border mb-2">
                    <NetworkImage src={item.product.photo_1} alt={item.id} />
                    <div className="d-flex flex-column flex-grow-1 px-3 py-2">
                      <span className="fw-bold">{toTitleCase(item.product.name)} {(details?.order_replace && getReplaceOrderProductIds().includes(item.product.id)) && <span className="text-primary">(Replacement)</span>}</span>
                      <span>Size: {item.size}</span>
                      <span>Quantity: {item.quantity}</span>
                      <span>One Piece Price: {item.product.price}</span>
                    </div>
                    <div className="p-2">
                      <span className="fw-bold text-danger">Rs. {parseFloat(item.product.price) * item.quantity}</span>
                    </div>
                  </div>
                );
              })}
              <span>
                <strong>Note:</strong> All Item price are included with tax. Keep shopping.
              </span>
              <hr />
              <div className="d-flex justify-content-between mb-3">
                <span className="fw-bold">Total Payable Amount {!details?.order.is_canceled && <span className={`text-${details?.order.payment_status === PaymentStatus.COMPLETED ? "success" : "danger"}`}>({details?.order.payment_status === PaymentStatus.COMPLETED ? "Paid" : "Pending"})</span>}</span>
                <span className="fw-bold text-danger">Rs. {details?.order.payable_amount}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
