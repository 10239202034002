import "../styles/Marquee.css";
import React from "react";

export default function Marquee({ messages }: { messages: string[] }) {
  return (
    <div className="marquee bg-brand p-2">
      <div className="marquee-text text-dark">
        {messages.map((message, index) => (
          <div key={index} className="marquee-item">
            {message}
          </div>
        ))}
      </div>
    </div>
  );
}
